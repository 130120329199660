import { ReactNode } from 'react'

interface ButtonI {
  text: string
  className: string
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  children?: ReactNode
  onClick?: () => void
  background?:
    | 'transparent'
    | 'fill'
    | 'transparent-white'
    | 'fill-white'
    | 'fill-green'
    | 'fill-red'
    | 'fill-pink'
  isSubmitButton?: boolean
  isButtonDisable?: boolean
}

const Button = (props: ButtonI) => {
  const {
    children,
    className,
    onClick,
    size,
    background,
    isSubmitButton = false,
    isButtonDisable = false,
  } = props

  const handleButtonClick = () => {
    if (!isButtonDisable) {
      onClick && onClick()
    }
  }

  const getSizeVariant = () => {
    let clsName = ''
    switch (size) {
      case 'sm':
        clsName = 'px-6 h-6'
        break
      case 'md':
        clsName = 'px-8 h-8'
        break
      case 'lg':
        clsName = 'px-12 h-12'
        break
      case 'xl':
        clsName = 'px-14 h-14'
        break
      case 'xxl':
        clsName = 'w-72 h-16'
        break
      default:
        clsName = 'px-8 h-8'
        break
    }
    return clsName
  }

  const getBackgroundVariant = () => {
    let clsName = ''
    switch (background) {
      case 'fill':
        clsName =
          'bg-[#F8AE3B] text-white hover:bg-[#FF007A] hover:text-white hover:border-[#FF007A]'
        break
      case 'fill-white':
        clsName =
          'bg-white text-[#F8AE3B] border-1  border-[#F8AE3B] hover:bg-[#8DC95D] hover:text-white hover:border-[#8DC95D]'
        break
      case 'transparent':
        clsName =
          'bg-transparent border border-[#FF007A] border-1 hover:bg-[#FF007A] text-white hover:border-[#FF007A]'
        break
      case 'transparent-white':
        clsName =
          'bg-transparent border border-white-500 border-1 text-white hover:bg-[#8DC95D] hover:text-white hover:border-[#8DC95D]'
        break
      case 'fill-pink':
        clsName = 'bg-[#FF007A] text-white hover:bg-blue-700'
        break
      default:
        clsName = 'bg-[#FF007A] text-white hover:bg-blue-700'
        break
    }
    return clsName
  }

  return (
    <button
      className={`${className} rounded-full ${getSizeVariant()} ${getBackgroundVariant()}`}
      onClick={() => (onClick ? handleButtonClick() : {})}
      type={isSubmitButton ? 'submit' : 'button'}
      disabled={isButtonDisable}
    >
      {children}
    </button>
  )
}

export default Button