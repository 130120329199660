import { Typography } from "../../components"
import { Spin1Logo } from "../../images"

const RocketGrid = () => {
    return <><div className="col-span-2 grid md:grid-cols-2 grid-cols-1 gap-1 max-sm:order-1 place-items-start max-sm:place-items-center">
    <video
      width="100%"
      height="auto"
      controls={false}
      muted
      autoPlay
      loop
      className="max-sm:w-full max-sm:h-200"
    >
      <source src="rocket.mp4" type="video/mp4"></source>
      Your browser does not support the video tag.
    </video>
    <div className="grid grid-cols-1 lg:pr-40 max-sm:place-items-center">
  <Typography size={'md'} type={'regular'} className="mb-4 lg:pt-44 md:pt-14">
              A Unit of
          </Typography>
          <img src={Spin1Logo} alt="spin1" className="h-2/3" />
  </div>
  </div>
 
  </>
}

export default RocketGrid